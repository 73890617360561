<template>
  <en-dialog :model-value="modelValue" width="80%" title="工单列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax v-if="store.accessRightsHash.WORK_ORDER_ENOCH_EXPORT" type="primary" link :method="operation.export.click">导出工单</button-ajax>
      <button-ajax
        v-if="store.user?.tenant?.id === 'ENOCH' && store.accessRightsHash.WORK_ORDER_ENOCH_EXPORT"
        type="primary"
        link
        :method="operation.exportDetails.click"
      >
        导出明细
      </button-ajax>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['WOSTATUS']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="store.user?.tenant?.id === 'ENOCH' ? table.getByEnoch : table.getByTenant"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SPRWOFD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="store.user?.tenant?.id === 'ENOCH' ? table.getByEnoch : table.getByTenant"
      pagination
      :config="table.config"
    >
      <template #FREIGHT_DOCUMENT="{ row }: { row: EnospraySprayDefinitions['WorkOrderQueryDto'] }"> </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'

import { EnMessageBox } from '@enocloud/components'

export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        export: {
          click() {
            return this.ajax(
              this.store.user?.tenant?.id === 'ENOCH' ? 'GET /enospray/workorder/enoch/export' : 'GET /enospray/workorder/export',
              this.table.$ajaxParams
            )
          }
        },
        exportDetails: {
          click() {
            return this.ajax('GET /enospray/workorder/enoch/surface/export', this.table.$ajaxParams)
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          getByEnoch: {
            action: 'GET /enospray/workorder/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          },
          getByTenant: {
            action: 'GET /enospray/workorder/enoch/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          SERVICE_SERIAL_NO: { header: { filter: { type: 'text', field: 'serviceSerialNo' } } },
          LOGISTICS_COMPANY: { header: { filter: { type: 'text', field: 'logisticsCompany' } } },
          SERVICE_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceStatus',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['SVCSTAT']
                      params.payload = { excludes: ['PR', 'DC'] }
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          REWORK: {
            header: {
              filter: {
                type: 'select',
                field: 'rework',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['FLAG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          COLOR_FAMILY: {
            header: {
              filter: {
                type: 'select',
                field: 'colorFamily',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['COLFML']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          COLOR_EFFECT: {
            header: {
              filter: {
                type: 'select',
                field: 'colorFamily',
                props: {
                  options: [
                    { code: 'M', message: '金属漆' },
                    { code: 'P', message: '纯色漆' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          MAINTENANCE_NAMES: {
            header: {
              filter: {
                type: 'select',
                field: 'surfaceMessage',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['SURFACE']
                      params.payload = { excludes: ['NA'] }
                    }
                  },
                  props: { label: 'message', value: 'message' }
                }
              }
            }
          },
          MEASURING_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'autoColor',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['MSRTYPE']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          HAS_JOINT_WARRANTY: {
            header: {
              filter: {
                type: 'select',
                field: 'hasJointWarranty',
                props: {
                  ajax: {
                    action: 'GET /enospray/common/lookup/:lookupType',
                    params(params) {
                      params.paths = ['FLAG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          WORKING_TEAM: { header: { filter: { type: 'text', field: 'workingTeam' } } },
          ASSIGNEE: { header: { filter: { type: 'text', field: 'assignees' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          COLOR_CODE: { header: { filter: { type: 'text', field: 'colorCode' } } },
          COLOR_NAME: { header: { filter: { type: 'text', field: 'colorName' } } },
          YEAR: { header: { filter: { type: 'text', field: 'year' } } },
          VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'vehicleBrand' } } },
          PREPARED_BY: { header: { filter: { type: 'text', field: 'preparedBy' } } },
          PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['preparedStartDate', 'preparedEndDate'], props: { type: 'daterange' } } } },
          SPRAY_DATETIME: { header: { filter: { type: 'date', field: ['sprayDateStart', 'sprayDateEnd'], props: { type: 'daterange' } } } },
          COMMIT_DATETIME: { header: { filter: { type: 'date', field: ['businessStartDate', 'businessEndDate'], props: { type: 'daterange' } } } }
        },
        children: {
          logisiticsImageUrls: {
            click(row: EnocloudInventoryDefinitions['PurchaseQueryDto']) {
              EnMessageBox({
                title: '物流单照片',
                message: h(
                  'div',
                  { class: 'flex gap-4 flex-wrap' },
                  row.logisiticsImageUrls.map((url) => {
                    return h('img', { src: url, width: 300, height: 300 })
                  })
                )
              })
            }
          }
        }
      }
    }
  },

  mounted() {
    if (this.store.user?.tenant?.id === 'ENOCH') this.table.getByEnoch()
    else this.table.getByTenant()
  }
})
</script>
